<template>
  <v-container fluid>
    <alert-table
      :route="'confirmed'"
      :headers="headers"
      :expanded-headers="expanded"
      :data="data"
      :loading="loading"
      :table-page="page"
      :table-page-total-count="pageTotalCount"
      :table-size="size"
      :processing="processing"
      @change="loadData($event.page, $event.size, $event.sortDesc, $event.by)"
      @proceed="revertAlerts($event)"
    />
  </v-container>
</template>

<script>
import alertTable from "@/views/monitor/auditor/cve/components/tableConfirmed.vue";
import { Errors } from "@/utils/error";
import { getConfirmedCveAlerts, revertCveAlerts } from "@/api/monitor/auditor";

export default {
  components: {
    alertTable,
  },
  data() {
    return {
      headers: [],
      expanded: [],
      data: [],
      loading: true,
      processing: false,

      page: 1,
      pageTotalCount: 1,
      size: 25,
      sizeTotalCount: 0,
    };
  },
  computed: {
    changeInAddForm: {
      get() {
        return this.$store.state.settings.changeInAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "changeInAddForm",
          value: val,
        });
      },
    },
    monitorAuditorCveConfirmedTable: {
      get() {
        return this.$store.state.tables.monitorAuditorCveConfirmedTable;
      },
      set(val) {
        this.$store.dispatch("store/tables/changeSetting", {
          key: "monitorAuditorCveConfirmedTable",
          value: val,
        });
      },
    },
  },
  watch: {
    changeInAddForm: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue && newValue === "auditorAlert") {
          this.loadData(this.page, this.size);
        }
        this.changeInAddForm = "";
      },
    },
    monitorAuditorCveConfirmedTable: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) {
          const self = this;
          this.headers = [];
          newValue.headers.forEach(function (header) {
            if (header.visible) {
              self.headers.push(header);
            }
          });
          this.expanded = [];
          newValue.expanded.forEach(function (item) {
            if (item.visible) {
              self.expanded.push(item);
            }
          });
        }
      },
    },
  },
  created() {
    this.loadData(this.page, this.size);
  },
  methods: {
    loadData(page, size, sortDesc, by) {
      this.loading = true;
      this.processing = false;
      let newPage = page;
      if (size !== this.size) {
        newPage = 1;
      }
      getConfirmedCveAlerts(newPage, size, sortDesc, by)
        .then((response) => {
          this.loading = false;
          this.data = response.data;
          this.data.forEach(function (value) {
            value.deleteMenu = false;
          });
          this.pageTotalCount = response.last_page;
          this.page = response.current_page;
          this.size = parseInt(response.per_page);
          this.sizeTotalCount = response.total;
        })
        .catch((e) => {
          Errors.show(e);
          this.loading = false;
        });
    },
    revertAlerts(allData) {
      this.processing = true;
      const ids = allData.selected.map((alert) => alert.id);
      const data = {
        alerts: ids,
      };
      revertCveAlerts(data)
        .then(() => {
          this.$toast.open({
            message: "Alerts were successfully reverted!",
            type: "success",
            position: "top-right",
            duration: 2000,
          });
          this.loadData(1, this.size);
          this.processing = false;
        })
        .catch((e) => {
          Errors.show(e);
          this.processing = false;
        });
    },
  },
};
</script>
